<template>
  <el-menu router background-color="#545c64" text-color="#eee" active-text-color="#ffd04b" class="menubar" :default-active="$route.path">
    <el-menu-item v-for="value in reports" :key="value.versionId" :index="`/report/reportdefine/${value.versionId}`">
      <i class="el-icon-s-data" style="color: #fff;"></i>
      <span slot="title" @click="gotoUrl(`/report/reportdefine/${value.versionId}`)">{{ value.versionName }}</span>
    </el-menu-item>
  </el-menu>
</template>

<script>
export default {
  name: "ReportSidebar",
  props: ["reports", "userRole"],
  methods: {
    download() {
      window.open("/逢泰操作手冊-廠商查詢手冊.pdf");
    },
    gotoUrl(url) {
      location.href = url;
    }
  }
};
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
.menubar>li {
  width: calc(100% + 1px);
}
</style>
