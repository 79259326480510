<template>
  <div>
    <div style="padding:25px 0px 25px 20px;color:#eee;font-size:18px;">
      <span> 帳號：{{ userName() }}</span>
    </div>
    <el-menu router background-color="#545c64" text-color="#fff" active-text-color="#ffd04b" class="menubar"
      :default-active="$route.path">
      <el-submenu index="1">
        <template slot="title">
          <i class="el-icon-folder"></i>
          <span>上傳檔案管理</span>
        </template>
        <el-menu-item index="/setting/basedefines">
          <i class="el-icon-document-checked"></i>
          <span slot="title">原始上傳檔案格式</span>
        </el-menu-item>
        <el-menu-item index="/setting/templatedefines">
          <i class="el-icon-document-add"></i>
          <span slot="title">客製上傳格式</span>
        </el-menu-item>
        <el-menu-item index="/setting/uploaddefinetemplates">
          <i class="el-icon-document"></i>
          <span slot="title">授權供應商上傳</span>
        </el-menu-item>
      </el-submenu>
      <el-submenu index="2">
        <template slot="title">
          <i class="el-icon-printer"></i>
          <span>報表格式管理</span>
        </template>
        <el-menu-item index="/setting/reportdefines">
          <i class="el-icon-s-data"></i>
          <span slot="title">報表格式管理</span>
        </el-menu-item>
      </el-submenu>
      <el-submenu index="3">
        <template slot="title">
          <i class="el-icon-s-cooperation"></i>
          <span>系統管理</span>
        </template>
        <el-menu-item index="/setting/companies">
          <i class="el-icon-office-building"></i>
          <span slot="title">公司管理</span>
        </el-menu-item>
        <!-- <el-menu-item index="/setting/accounts">
        <i class="el-icon-user"></i>
        <span slot="title">帳號管理</span>
      </el-menu-item> -->
        <el-menu-item index="/setting/authorities">
          <i class="el-icon-s-claim"></i>
          <span slot="title">權限管理</span>
        </el-menu-item>
      </el-submenu>
      <el-menu-item index="/supplier/upload">
        <i class="el-icon-connection" style="color:#fff"></i>
        <span slot="title">到上傳系統</span>
      </el-menu-item>
    </el-menu>
  </div>
</template>
<script>
import { getUser } from "@/utils/auth";
export default {
  name: "SupplierSidebar",
  methods: {
    userName() {
      var user = getUser();
      return `${user.name}`;
    }
  }
};
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
.menubar>li {
  width: calc(100% + 1px);
}
</style>
