import Cookies from "js-cookie";

const TokenKey = "Admin-Token";

export function getUser() {
  var tokenString = Cookies.get(TokenKey);
  if (!tokenString) return {};

  var tokens = tokenString.split(".");
  var token = tokens[1];
  if (!token) return {};

  token = token.replace(/-/g, "+").replace(/_/g, "/");
  var jsonPayload = decodeURIComponent(
    atob(token)
      .split("")
      .map(function (c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join("")
  );
  var user = JSON.parse(jsonPayload);
  user = JSON.parse(user.user);
  return user;
}

export function getToken() {
  return Cookies.get(TokenKey);
}

export function setToken(token) {
  return Cookies.set(TokenKey, token);
}

export function removeToken() {
  return Cookies.remove(TokenKey);
}

export function isMobile() {
  if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
    return true
  } else {
    var screen_width = window.screen.width;
    var screen_height = window.screen.height;
    console.log(screen_width, screen_height)
    return screen_width < 500 && screen_height < 999;
    //return false
  }
}
