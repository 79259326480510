<template>
  <el-container style="border: 1px solid #eee">
    <el-aside width="206px" style="overflow-x: hidden;">
      <setting-sidebar :userRole="userRole"></setting-sidebar>
    </el-aside>
    <el-container style="background-color: rgb(238, 241, 246)">
      <el-header>
        <setting-header></setting-header>
      </el-header>
      <el-main>
        <router-view :key="key" />
      </el-main>
    </el-container>
  </el-container>
</template>

<script>
import { getToken } from "@/utils/auth";
//import request from "@/utils/request";
import SettingSidebar from "./settingsidebar";
import SettingHeader from "./settingheader";
export default {
  name: "SettingLayout",
  components: { SettingSidebar, SettingHeader },
  computed: {
    key() {
      return this.$router.fullPath;
    },
    userRole() {
      var tokens = getToken().split(".");
      var token = tokens[1];
      token = token.replace(/-/g, "+").replace(/_/g, "/");
      var jsonPayload = decodeURIComponent(
        atob(token)
          .split("")
          .map(function (c) {
            return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
          })
          .join("")
      );
      var user = JSON.parse(jsonPayload);
      user = JSON.parse(user.user);
      return user.role;
    }
  },
  methods: {
    logout() {
      console.log("logout"); // eslint-disable-line
      this.$store.dispatch("user/resetToken").then(() => {
        location.reload();
      });
    }
  },
  async mounted() {
    try {
      if (getToken()) {
        if (["administrator", "ftuser"].indexOf(this.userRole) < 0) {
          this.logout();
        }
      }
      //var values = await request.get("test");
      // console.log(values); // eslint-disable-line
    } catch (e) {
      console.log("Error", e); // eslint-disable-line
    }
  }
};
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
.el-header {
  //background-color: rgb(200, 212, 200);
  background-color: #327fcc;
}

.el-aside {
  background-color: #545c64;
  // background-color: #fff;
  padding: 0px;
}

::-webkit-scrollbar {
  width: 0.5em;
}

::-webkit-scrollbar-thumb {
  background: #ccc
}

.el-main {
  background-color: rgb(238, 241, 246);
  min-height: calc(100vh - 79px);
}
</style>