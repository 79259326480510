<template>
  <div>
    <div>
      <div style="padding:25px 0px 25px 20px;color:#eee;font-size:18px;">
        <span> 帳號：{{ userName() }}</span>
      </div>
      <el-menu router background-color="#545c64" text-color="#eee" active-text-color="#ffd04b" class="menubar" :default-active="$route.path" @select="handleSelect">
        <!-- <el-submenu index="1">
            <template slot="title"><i class="el-icon-message"></i>檔案上傳</template>
        </el-submenu>-->
        <el-menu-item index="/supplier/upload" v-if="userRole != 'supplier'">
          <i class="el-icon-upload" style="color: #fff;"></i>
          <span slot="title">訂單交換資料上傳</span>
        </el-menu-item>
        <el-submenu index="2" v-if="reports && reports.length > 0">
          <template slot="title">
            <i class="el-icon-printer" style="color: #fff;"></i>
            <span>統計報表下載</span>
          </template>
          <el-menu-item v-for="value in reports" :key="value.versionId" :index="`/supplier/reportdefine/${value.versionId}`">
            <i class="el-icon-s-data" style="color: #fff;"></i>
            <span slot="title" @click="gotoUrl(`/supplier/reportdefine/${value.versionId}`)">{{ value.versionName
            }}</span>
          </el-menu-item>
        </el-submenu>
        <el-submenu index="3" v-if="customizeReports != null && customizeReports.length > 0">
          <template slot="title">
            <i class="el-icon-search" style="color: #fff;"></i>
            <span>採購單查詢</span>
          </template>
          <el-menu-item v-for="x in customizeReports" :key="x.versionId" :index="`/supplier/customize/${x.versionId}`">
            <i class="el-icon-tickets" style="color: #fff;"></i>
            <span slot="title">{{ x.versionName }}</span>
          </el-menu-item>
        </el-submenu>
        <el-menu-item index="" @click="download">
          <i class="el-icon-magic-stick" style="color: #fff;"></i>
          <span slot="title">操作手冊下載</span>
        </el-menu-item>

        <el-menu-item v-if="userRole == 'administrator'" index="/setting">
          <i class="el-icon-connection" style="color: #fff;"></i>
          <span slot="title">到後臺管理</span>
        </el-menu-item>
      </el-menu>
    </div>
  </div>
</template>

<script>
import { getUser } from "@/utils/auth";
export default {
  name: "SupplierSidebar",
  props: ["reports", "customizeReportTitle", "customizeReports", "userRole", "show"],
  methods: {
    userName() {
      var user = getUser();
      return `${user.name}`;
    },
    download() {
      window.open("/逢泰操作手冊-廠商查詢手冊.pdf");
    },
    gotoUrl(url) {
      location.href = url;
      this.$emit("update:show", false);
    },
    handleSelect() {
      this.$emit("update:show", false);
    }
  }
};
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
.menubar>li {
  width: calc(100% + 1px);
}
</style>
