<template>
  <el-row type="flex" justify="space-between">
    <el-col>
      <div class="header_logo" style="width:80px;overflow: hidden;float:left">
        <a href="/">
          <!-- <img src="~@/assets/logo.png" alt="FlowTide" logo /> -->
          <img src="/logo.png" alt="FlowTide" logo />
        </a>
      </div>
      <div v-if="$route.name" class="header_user">
        <span style="font-size:22px; color:#fff"> &nbsp;&nbsp; {{ $route.name }} </span>
      </div>
    </el-col>
    <el-col align="right">
      <div class="header_user">
        <el-dropdown :hide-timeout="800">
          <span style="font-size:22px; color:#fff">
            <i class="el-icon-s-custom" style="color:#fff"></i>
            {{ userCompanyName }}
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item v-if="userRole == 'administrator'" icon="el-icon-switch-button" style="font-size:16px;" @click.native="gotoSupplier">
              <span> 上傳系統</span>
            </el-dropdown-item>
            <el-dropdown-item icon="el-icon-switch-button" style="font-size:16px;" @click.native="logout">
              <span> 登出</span>
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </el-col>
  </el-row>
</template>

<script>
import { getToken } from "@/utils/auth";
//import request from "@/utils/request";
export default {
  name: "SettingHeader",
  computed: {
    userCompanyName() {
      var user = this.getUser();
      return `(${user.company.name})`;
    },
    userRole() {
      var user = this.getUser();
      return user.role;
    }
  },
  methods: {
    getUser() {
      var tokens = getToken().split(".");
      var token = tokens[1];
      token = token.replace(/-/g, "+").replace(/_/g, "/");
      var jsonPayload = decodeURIComponent(
        atob(token)
          .split("")
          .map(function (c) {
            return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
          })
          .join("")
      );
      var user = JSON.parse(jsonPayload);
      return JSON.parse(user.user);
    },
    gotoSupplier() {
      window.location.href = "/supplier/upload";
    },
    async logout() {
      console.log("logout"); // eslint-disable-line
      try {
        await this.$store.dispatch("user/resetToken");
        location.href = "/";
      } catch (ex) {
        location.reload();
      }
    }
  }
};
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
// .header_row {
//   height: 60px;
// }
// body > .el-container {
//   margin-bottom: 40px;
// }
// .el-container:nth-child(5) .el-aside,
// .el-container:nth-child(6) .el-aside {
//   line-height: 260px;
// }
// .el-container:nth-child(7) .el-aside {
//   line-height: 320px;
// }
.header_logo {
  // flex: 3;
  // display: flex;
  // justify-content: left;
  // // margin-left: 60px;
  padding-top: 3px;
}

.header_user {
  // display: flex;
  justify-content: right;
  // width: 200px;
  margin-top: 15px;
}

// .header_search {
//   display: flex;
//   float: right;
//   width: 200px;
// }
</style>
