import Vue from "vue";
import App from "./App.vue";
import ElementUI from "element-ui";
import locale from "element-ui/lib/locale/lang/zh-TW";
import { isMobile } from "@/utils/auth";

import "element-ui/lib/theme-chalk/index.css";
import "axios-progress-bar/dist/nprogress.css";
import "font-awesome/css/font-awesome.css";

import "@/styles/index.scss"; // global css
Vue.use(ElementUI, { locale });

import router from "./router";
import store from "./store";
import SvgIcon from "vue-svgicon";

Vue.use(SvgIcon, { tagName: "svgicon" });

Vue.config.productionTip = true;
Vue.prototype.$isMobile = isMobile;

console.log("start env:", process.env); // eslint-disable-line

new Vue({
  render: h => h(App),
  router,
  store
}).$mount("#app");
