<template>
  <el-container style="border: 1px solid #eee">
    <el-aside v-if="!$isMobile()" width="205px" style="background-color:#545c64;overflow-x: hidden;">
      <supplier-sidebar :reports="reports" :customize-reports="customizeReports" :userRole="userRole"></supplier-sidebar>
    </el-aside>
    <el-container style="background-color:#545c64;">
      <el-header>
        <supplier-header :reports="reports" :customize-reports="customizeReports"></supplier-header>
      </el-header>
      <el-main>
        <router-view :key="key" />
      </el-main>
    </el-container>
  </el-container>
</template>

<script>
import { getToken } from "@/utils/auth";
import request from "@/utils/request";
import SupplierSidebar from "./suppliersidebar";
import SupplierHeader from "./supplierheader";
export default {
  name: "SupplierLayout",
  data() {
    return {
      reports: [],
      customizeReports: []
    };
  },
  components: { SupplierSidebar, SupplierHeader },
  computed: {
    isFtRole() {
      if (this.userRole == "administrator" || this.userRole == "ftuser") {
        return true;
      } else {
        return false;
      }
    },
    userCompanyId() {
      if (getToken() == null) return null;
      var tokens = getToken().split(".");
      var token = tokens[1];
      token = token.replace(/-/g, "+").replace(/_/g, "/");
      var jsonPayload = decodeURIComponent(
        atob(token)
          .split("")
          .map(function (c) {
            return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
          })
          .join("")
      );
      var user = JSON.parse(jsonPayload);
      user = JSON.parse(user.user);
      return user.companyId;
    },
    userRole() {
      if (getToken() == null) return null;
      var tokens = getToken().split(".");
      var token = tokens[1];
      token = token.replace(/-/g, "+").replace(/_/g, "/");
      var jsonPayload = decodeURIComponent(
        atob(token)
          .split("")
          .map(function (c) {
            return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
          })
          .join("")
      );
      var user = JSON.parse(jsonPayload);
      user = JSON.parse(user.user);
      return user.role;
    },
    key() {
      return this.$router.fullPath;
    }
  },
  methods: {},
  async mounted() {
    try {
      var rpts = await request.get(`allreportdefines/${this.userCompanyId}`);
      console.log("reports", rpts); // eslint-disable-line
      this.reports = rpts.filter(rpt => rpt.isTest == "False" && rpt.isCustomize == "False");
      this.customizeReports = rpts.filter(rpt => rpt.isTest == "False" && rpt.isCustomize == "True");
    } catch (e) {
      console.log("Error", e); // eslint-disable-line
    }
  }
};
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
.el-header {
  background-color: #327fcc;
  // background-color: #79c499;
  // background-color: rgb(200, 212, 200);
  // background-color: #5a636b;
  // background-color: #545e6a;
  // color: #f6f6f6;
  // line-height: 60px;
  // justify-content: center;
  // align-items: center;
}

.el-aside {
  // background-color: #3d464f;
  // color: #828692;
  // text-align: center;
  // line-height: 600px;
  background-color: #fff;
  padding: 0px;
  height: calc(100vh - 15px);

}

::-webkit-scrollbar {
  width: 0.5em;
}

::-webkit-scrollbar-thumb {
  background: #ccc
}

.el-main {
  // background-color: #283647;
  background-color: rgb(238, 241, 246);
  // text-align: center;
  min-height: calc(100vh - 79px);
}
</style>
