<template>
  <el-container style="border: 1px solid #eee">
    <el-header>
      <report-header></report-header>
    </el-header>
    <el-container style="background-color:#545c64">
      <el-aside width="205px" style="background-color:#545c64;overflow-x: hidden;">
        <report-sidebar :reports="reports" :userRole="userRole"></report-sidebar>
      </el-aside>
      <el-main>
        <router-view :key="key" />
      </el-main>
    </el-container>
  </el-container>
</template>

<script>
import { getToken } from "@/utils/auth";
import request from "@/utils/request";
import ReportSidebar from "./reportsidebar";
import ReportHeader from "./reportheader";
export default {
  name: "ReportLayout",
  data() {
    return {
      reports: []
    };
  },
  components: { ReportSidebar, ReportHeader },
  computed: {
    userCompanyId() {
      if (getToken() == null) return null;
      var tokens = getToken().split(".");
      var token = tokens[1];
      token = token.replace(/-/g, "+").replace(/_/g, "/");
      var jsonPayload = decodeURIComponent(
        atob(token)
          .split("")
          .map(function (c) {
            return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
          })
          .join("")
      );
      var user = JSON.parse(jsonPayload);
      user = JSON.parse(user.user);
      return user.companyId;
    },
    userRole() {
      if (getToken() == null) return null;
      var tokens = getToken().split(".");
      var token = tokens[1];
      token = token.replace(/-/g, "+").replace(/_/g, "/");
      var jsonPayload = decodeURIComponent(
        atob(token)
          .split("")
          .map(function (c) {
            return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
          })
          .join("")
      );
      var user = JSON.parse(jsonPayload);
      user = JSON.parse(user.user);
      return user.role;
    },
    key() {
      return this.$router.fullPath;
    }
  },
  methods: {},
  async mounted() {
    try {
      var rpts = await request.get(`allreportdefines/${this.userCompanyId}`);
      console.log("reports", rpts); // eslint-disable-line
      this.reports = rpts.filter(rpt => rpt.isTest == "False" && rpt.isCustomize == "False" && rpt.isSupplierReport == "True");
    } catch (e) {
      console.log("Error", e); // eslint-disable-line
    }
  }
};
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
.el-header {
  background-color: #327fcc;
}

.el-aside {
  background-color: #fff;
  padding: 0px;
  height: 100%;
}

::-webkit-scrollbar {
  width: 0.5em;
}

::-webkit-scrollbar-thumb {
  background: #ccc
}

.el-main {
  background-color: rgb(238, 241, 246);
  min-height: calc(100vh - 79px);
}
</style>
