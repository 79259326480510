import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

// /* Layout */
import SupplierLayout from "@/layout/supplier/supplierlayout.vue";
import SettingLayout from "@/layout/setting/settinglayout.vue";
import ReportLayout from "@/layout/report/reportlayout.vue";

/**
 * constantRoutes
 * a base page that does not have permission requirements
 * all roles can be accessed
 */
export const constantRoutes = [
  {
    path: "/supplier",
    component: SupplierLayout, // layout 是掛上 header 跟 sidebar 的地方
    hidden: true,
    children: [
      // children 用來放主要功能項(大小寫要對)
      {
        path: "",
        name: "主頁",
        component: () => import("@/views/supplier/upload")
      },
      {
        path: "upload",
        name: "訂單交換資料上傳",
        component: () => import("@/views/supplier/upload")
      },
      {
        path: "reportdefine/:versionId",
        name: "統計報表下載",
        component: () => import("@/views/supplier/ReportDefine")
      },
      {
        path: "customize/slorders",
        name: "採購進貨單",
        component: () => import("@/views/supplier/customize/SLOrders")
      },
      {
        path: "customize/rmaorders",
        name: "退廠單查詢",
        component: () => import("@/views/supplier/customize/RmaOrders")
      }
    ]
  },
  {
    path: "/setting",
    component: SettingLayout, // layout 是掛上 header 跟 sidebar 的地方
    hidden: true,
    children: [
      // children 用來放主要功能項(大小寫要對)
      {
        path: "",
        name: "後臺管理首頁",
        component: () => import("@/views/setting/index")
      },
      // BaseDefines
      {
        path: "BaseDefines",
        name: "原始上傳檔案格式",
        component: () => import("@/views/setting/BaseDefines")
      },
      {
        path: "BaseDefines/:filename/Columns",
        name: "原始上傳檔案格式-欄位設定",
        component: () => import("@/views/setting/BaseDefineColumns")
      },
      // TemplateDefines
      {
        path: "TemplateDefines",
        name: "客製上傳格式",
        component: () => import("@/views/setting/TemplateDefines")
      },
      {
        path: "TemplateDefines/:tableName/:templateName/Columns",
        name: "客製上傳格式-欄位設定",
        component: () => import("@/views/setting/TemplateDefineColumns")
      },
      // UploadDefines

      {
        path: "UploadDefineTemplates",
        name: "授權供應商上傳",
        component: () => import("@/views/setting/UploadDefineTemplates")
        // children: [
        //   {
        //     path: "Columns",
        //     name: "檢核欄位管理",
        //     component: () => import("@/views/setting/UploadDefineColumns")
        //   }
        // ]
      },
      {
        path: "UploadDefineTemplates/:templateName/UploadDefines",
        name: "授權供應商上傳-選擇範本",
        component: () => import("@/views/setting/UploadDefines")
        // children: [
        //   {
        //     path: "Columns",
        //     name: "檢核欄位管理",
        //     component: () => import("@/views/setting/UploadDefineColumns")
        //   }
        // ]
      },
      {
        path: "UploadDefineTemplates/:templateName/UploadDefines/:versionId/Columns",
        name: "授權供應商上傳--選擇範本-檢核欄位管理",
        component: () => import("@/views/setting/UploadDefineColumns")
      },
      // Companies
      {
        path: "Companies",
        name: "公司管理",
        component: () => import("@/views/setting/Companies")
      },
      // Accounts
      {
        path: "Companies/:companyId/Accounts",
        name: "帳號管理",
        component: () => import("@/views/setting/Accounts")
      },
      // Authority
      {
        path: "Authorities",
        name: "權限管理",
        component: () => import("@/views/setting/Authorities")
      },
      // ReportDefines
      {
        path: "reportdefines",
        name: "報表格式管理",
        component: () => import("@/views/setting/ReportDefines")
      },
      {
        path: "reportdefines/:versionId/columns",
        name: "報表格式管理-欄位管理",
        component: () => import("@/views/setting/ReportDefineColumns")
      },
      {
        path: "reportdefines/:versionId/companies",
        name: "報表格式管理-公司授權",
        component: () => import("@/views/setting/ReportDefineCompanyLinks")
      }
    ]
  },
  {
    path: "/report",
    component: ReportLayout,
    hidden: true,
    children: [
      // children 用來放主要功能項(大小寫要對)
      {
        path: "",
        name: "客戶統計報表主頁",
        component: () => import("@/views/supplier/index")
      },
      {
        path: "reportdefine/:versionId",
        name: "客戶統計報表下載",
        component: () => import("@/views/supplier/ReportDefine")
      }
    ]
  },

  // 以下項目放的是不用登入的地方
  {
    path: "/",
    // component: () => import("@/views/Home"),
    component: () => import("@/views/login"),
    hidden: true
  },
  {
    path: "/login",
    component: () => import("@/views/login"),
    hidden: true
    //alias: ''
  },
  // {
  //   path: "/settinglogin",
  //   component: () => import("@/views/login/settinglogin"),
  //   hidden: true
  //   //alias: ''
  // },
  {
    path: "/auth-redirect",
    component: () => import("@/views/login/auth-redirect"),
    hidden: true
  },
  {
    path: "/404",
    component: () => import("@/views/error-page/404"),
    hidden: true
  },
  {
    path: "/401",
    component: () => import("@/views/error-page/401"),
    hidden: true
  }
];

const createRouter = () =>
  new Router({
    mode: "history", // require service support
    scrollBehavior: () => ({ y: 0 }),
    routes: constantRoutes
  });

const router = createRouter();

export function resetRouter() {
  const newRouter = createRouter();
  router.matcher = newRouter.matcher; // reset router
}

export default router;
