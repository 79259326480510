import axios from "axios";
import { Message, MessageBox } from "element-ui";
import store from "@/store";
import { getToken } from "@/utils/auth";
import { loadProgressBar } from "axios-progress-bar";

// create an axios instance
const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API, // url = base url + request url
  withCredentials: true, // send cookies when cross-domain requests
  timeout: 120000 // request timeout
});
loadProgressBar({}, service);

// request interceptor
service.interceptors.request.use(
  config => {
    // do something before request is sent
    if (store.getters.token) {
      // let each request carry token
      // ['X-Token'] is a custom headers key
      // please modify it according to the actual situation
      config.headers["Authorization"] = "Bearer " + getToken();
    }
    return config;
  },
  error => {
    // do something with request error
    console.log(error); // eslint-disable-line
    return Promise.reject(error);
  }
);

// response interceptor
service.interceptors.response.use(
  response => {
    const res = response.data;
    return res;
    // if the custom code is not 20000, it is judged as an error.

    // if (res.code !== 20000) {
    //   Message({
    //     message: res.message || 'error',
    //     type: 'error',
    //     duration: 5 * 1000
    //   })

    //   // 50008: Illegal token; 50012: Other clients logged in; 50014: Token expired;
    //   if (res.code === 50008 || res.code === 50012 || res.code === 50014) {
    //     // to re-login
    //     MessageBox.confirm('You have been logged out, you can cancel to stay on this page, or log in again', 'Confirm logout', {
    //       confirmButtonText: 'Re-Login',
    //       cancelButtonText: 'Cancel',
    //       type: 'warning'
    //     }).then(() => {
    //       store.dispatch('user/resetToken').then(() => {
    //         location.reload()
    //       })
    //     })
    //   }
    //   return Promise.reject(res.message || 'error')
    // } else {
    //   return res
    // }
  },
  error => {
    if (!error.response) {
      console.log("no response error", error); // eslint-disable-line
      MessageBox.alert("系統沒有回應，將導回到首頁", "出錯了", {
        confirmButtonText: "我知道了，導回首頁"
        //callback: () => (location.href = process.env.VUE_APP_BASE_WEB)
      });
    } else if (error.response.status == 401) {
      if (getToken()) {
        store.dispatch("user/resetToken").then(() => {
          MessageBox.alert("系統已經登出，將導回到首頁", "您已登出", {
            confirmButtonText: "我知道了",
            callback: () => (location.href = process.env.VUE_APP_BASE_WEB)
          });
        });
      } else {
        location.href = process.env.VUE_APP_BASE_WEB;
      }
    } else {
      console.log("err response", error.response); // eslint-disable-line
      if (error.response.data && error.response.data.error_description) {
        Message({
          message: error.response.data.error_description,
          type: "error",
          duration: 5 * 1000
        });
      } else {
        Message({
          message: error.message,
          type: "error",
          duration: 5 * 1000
        });
      }
    }
    return Promise.reject(error);
  }
);
export default service;
