<template>
  <el-row type="flex" justify="space-between">
    <!-- mobile時改到其他地方顯示 -->
    <el-col v-if="$isMobile()" :span="14">
      <i class="fa fa-2x fa-bars" style="padding:13px 0 0 0;color:#fff" @click="drawer = true"></i>
      <el-drawer custom-class="drawer-menu" :visible.sync="drawer" :show-close="false" :withHeader="false" direction="ltr" size="65%">
        <supplier-sidebar :reports="reports" :customize-reports="customizeReports" :userRole="userRole" :show.sync="drawer"></supplier-sidebar>
      </el-drawer>
    </el-col>
    <el-col>
      <div v-if="!$isMobile()" class="header_logo" style="width:80px;overflow: hidden;float:left">
        <a href="/">
          <!-- <img src="@/assets/logo.png" alt="FlowTide" logo /> -->
          <img src="/logo.png" alt="FlowTide" logo />
        </a>
      </div>
      <div v-if="$route.name" class="header_user">
        <span v-if="$isMobile()" style="font-size:18px; color:#fff"> {{ $route.name }} </span>
        <span v-else style="font-size:22px; color:#fff"> &nbsp;&nbsp;{{ $route.name }} </span>
      </div>
    </el-col>
    <el-col :span="$isMobile() ? 10 : 24" align="right">
      <div class="header_user">
        <el-dropdown :hide-timeout="800">
          <span style="font-size:22px; color:#fff">
            <i class="el-icon-s-custom" style="color:#fff"></i>
            <span v-if="!$isMobile()"> {{ userCompanyName }}</span>
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item v-if="$isMobile()" style="font-size:16px;">
              <span> {{ userCompanyName }}</span>
            </el-dropdown-item>
            <el-dropdown-item v-if="userRole == 'administrator'" icon="el-icon-connection" style="font-size:16px;" @click.native="gotoSetting">
              <span> 後臺設定</span>
            </el-dropdown-item>
            <el-dropdown-item icon="el-icon-key" style="font-size:16px;" @click.native="changePassword">
              <span> 修改密碼</span>
            </el-dropdown-item>
            <el-dropdown-item icon="el-icon-switch-button" style="font-size:16px;" @click.native="logout">
              <span> 登出</span>
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </el-col>
    <el-dialog title="修改密碼" :visible.sync="dialogVisible" width="30%" :close-on-press-escape="false" :close-on-click-modal="false">
      <el-form ref="changePasswordForm" label-width="80px" :rules="rules" :model="changePasswordVm">
        <el-form-item label="新密碼" prop="newPassword">
          <el-input type="password" v-model="changePasswordVm.newPassword" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="確認密碼" prop="checkPassword">
          <el-input type="password" v-model="changePasswordVm.checkPassword" autocomplete="off"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取消</el-button>
        <el-button type="primary" @click="submitForm('changePasswordForm')">更改密碼</el-button>
      </span>
    </el-dialog>
  </el-row>
</template>

<script>
import { getUser } from "@/utils/auth";
import request from "@/utils/request";
import SupplierSidebar from "./suppliersidebar";
export default {
  name: "SupplierHeader",
  components: { SupplierSidebar },
  props: ["reports", "customizeReportTitle", "customizeReports"],
  data() {
    var validatePass = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("請輸入密碼"));
      } else {
        if (this.changePasswordVm.checkPass !== "") {
          this.$refs.changePasswordForm.validateField("checkPassword");
        }
        callback();
      }
    };
    var validatePass2 = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("請再次輸入密碼"));
      } else if (value !== this.changePasswordVm.newPassword) {
        callback(new Error("兩次輸入密碼不一致!"));
      } else {
        callback();
      }
    };
    return {
      drawer: false,
      dialogVisible: false,
      changePasswordVm: {
        userPkey: getUser().pkey,
        newPassword: "",
        checkPassword: ""
      },
      rules: {
        newPassword: [{ validator: validatePass, trigger: "blur" }],
        checkPassword: [{ validator: validatePass2, trigger: "blur" }]
      }
    };
  },
  computed: {
    userName() {
      var user = getUser();
      return `${user.name}`;
    },
    userCompanyName() {
      var user = getUser();
      return `(${user.company.name})`;
    },
    userRole() {
      var user = getUser();
      return user.role;
    }
  },
  methods: {
    submitForm(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          request
            .put("accounts/changepassword", this.changePasswordVm)
            .then(res => {
              console.log("change passowrd response", res); //eslint-disable-line
              this.$message("使用者密碼已更改");
              this.dialogVisible = false;
            })
            .catch(error => {
              this.$message("使用者密碼更改失敗");
              console.log(error); //eslint-disable-line
            });
        } else {
          return false;
        }
      });
    },
    changePassword() {
      (this.changePasswordVm = {
        userPkey: getUser().pkey,
        newPassword: "",
        checkPassword: ""
      }),
        (this.dialogVisible = true);
    },
    gotoSetting() {
      window.location.href = "/setting";
    },
    async logout() {
      console.log("logout"); // eslint-disable-line
      try {
        await this.$store.dispatch("user/resetToken");
        location.href = "/";
        //location.reload();
      } catch (ex) {
        location.reload();
      }
    }
  }
};
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
// .header_row {
//   height: 60px;
// }
// body > .el-container {
//   margin-bottom: 40px;
// }
// .el-container:nth-child(5) .el-aside,
// .el-container:nth-child(6) .el-aside {
//   line-height: 260px;
// }
// .el-container:nth-child(7) .el-aside {
//   line-height: 320px;
// }
.header_logo {
  // flex: 3;
  // display: flex;
  // justify-content: left;
  // // margin-left: 60px;
  padding-top: 3px;
}

.header_user {
  // display: flex;
  justify-content: right;
  // width: 200px;
  margin-top: 15px;
}

// .header_search {
//   display: flex;
//   float: right;
//   width: 200px;
// }
</style>
